import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0003 = () => (
  <Wrapper>
    <ColumnH1 label="勤怠管理システムを導入したい！メリットや気を付けるべき点とは" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        ワーク・ライフ・バランスや働き方改革の流れを受け、
        <span>雇用形態が多様化</span>
        し、社員それぞれの状況に適した形で働くことのできる環境づくりが求められるようになりました。さらに、新型コロナウイルスの流行を受け、自粛要請に伴い
        <span>在宅勤務</span>を強いられることになった企業も多いことでしょう。
        <br />
        <br />
        ここで新たに注目されているのが、
        <span>「社員の勤怠管理をどのように行うべきか」</span>
        という、勤怠管理にまつわる問題です。
        <br />
        決まった時間にオフィスへ出社・退社し、タイムカードの打刻を行うような環境が当たり前ではなくなった昨今では、企業では勤怠管理の在り方も再度検討をしなければならない状況へ突入しています。同時に、勤怠管理システムの内容も進化し、多岐にわたる企業の現状に対応できるような仕組みが日々展開されています。
        <br />
        <br />
        今回は、現在の勤怠管理体制を更に整備し、さまざまな働き方に対応できるような勤怠管理システムの導入を検討している企業向けに、勤怠管理システムの概要やメリット、導入時の注意点について、順を追って解説をしてきます。
      </p>
      <img src="/images/column/details/c0003.jpg" alt="キンクラコラム画像3" />
      <h1>そもそも「勤怠管理」とは？</h1>
      <p>
        勤怠管理とは、会社が雇用する社員が働いている状況について、
        <span>労働時間などを記録することで把握・管理を行うこと</span>です。
        <br />
        具体的な方法としては、社員一人ひとりが、いつ出退社をしているのか、残業時間や休日出勤の状況はどうか、有給休暇の取得状況はどうか、などを詳細にわたり記録していきます。
        <br />
        <br />
        2019年の法改正により、これまでは対象外とされていた
        <span>管理監督者の勤怠管理</span>
        も義務づけられました。長時間労働や未払い残業代など、労働時間に関するさまざまな問題が起こらないよう、まずは勤怠管理を徹底して行うことが各企業に対して求められていることを覚えておきましょう。
      </p>
      <h1>勤怠管理にまつわる懸念点</h1>
      <p>
        勤怠管理に関して、昨今多く取りざたされている問題の一つに、
        <span>「リモートワーク勤務時の勤怠管理」</span>が挙げられます。
        <br />
        コロナ禍において、在宅勤務者の割合は依然と比べ大きく上昇しました。しかし、在宅勤務の場合、社員が何時から何時まで、どのように働いているのかを
        <span>会社側が把握することができない</span>というデメリットも生じます。
        <br />
        <br />
        また、在宅勤務が続く場合、仕事とプライベートの場所がともに自分の家であることから、
        <span>仕事と日常の切り替えがあいまい</span>
        となり、本来ならば日中に行うべき仕事を「深夜」に行ってしまう、という状況が生じやすくなります。
        <br />
        会社へ通常通り出社をしていれば起こり得なかったこのようなケースでも、会社側は当然ながら、深夜に仕事をした社員に対して深夜残業代を支払わなければなりません。また、メリハリをつけづらい生活を続ける在宅勤務者についても、
        <span>健康に支障をきたす可能性</span>が考えられます。
        <br />
        <br />
        このような状況に対応するため、まずは会社側が
        <span>
          社内体制に合致した勤怠管理システムなどを導入し、各社員に対して労働時間の記録を徹底
        </span>
        していく必要があります。ここからは、勤怠管理システムについて解説をしていきましょう。
      </p>
      <Introduction002 />
      <h1>勤怠管理システム導入のメリット</h1>
      <p>
        勤怠管理システムは、社内サーバーやクラウド上で社員の
        <span>勤怠状況をすぐに記録する</span>ことが可能となるものです。
        <br />
        昨今では、出退社状況だけではなく、有給休暇の申請や残日数の管理、シフト管理なども一括して行うことのできるシステムも展開されています。
        <br />
        <br />
        勤怠管理システムを導入することによるメリットには、まず企業として何より重要となる
        <span>「法律順守の徹底」</span>
        が挙げられます。システムを利用することで、義務化されている勤怠管理を簡単に行い、諸法令を遵守した対応を取る事ができます。
        <br />
        <br />
        また、勤怠管理システムの多くは、内容を修正した場合の履歴がシステム内に残るつくりになっています。これにより、紙媒体の勤怠管理体制では消しゴム一つで簡単に可能となる
        <span>「社員による勤務時間の不正修正」を防ぐ</span>
        ことが可能になります。
        <br />
        <br />
        さらに、勤怠管理システムを導入することで、給与計算担当者の負担を軽減することもできることを覚えておきましょう。
        <br />
        システムを介さずに社員の給与計算を実施する場合、各社員からの申請の受付や就労時間・残業時間の算出、有給休暇の残日数の管理など、担当者の業務は多岐にわたり、負担が大きくなります。また、人の手による計算には時として間違いが生じるものであることから、給与計算には計算実施者に加え、内容を確認する担当者の存在が求められます。
        <br />
        <br />
        勤怠管理システムを活用すれば、受付・計算・集計作業はすべてシステム上で簡潔するようになるため、
        <span>担当者の負担がかなり軽くなる</span>ことが見込まれます。
      </p>

      <h1>勤怠管理システム導入時の注意点</h1>
      <p>
        勤怠管理システムを導入する前に会社側が行うべき内容としては、まず社内体制を洗い出し、
        <span>勤怠に関するルール付けがしっかりと行われているかを確認する</span>
        ことが挙げられます。所定労働時間や残業代の付与、シフト管理体制、有給休暇の付与内容、賃金に上乗せする各種手当などの内容が明確にルール付けされており、就業規則などで定められているかをチェックし、不備があれば対応をします。
        <br />
        <br />
        会社に適した勤怠管理システムを検討するためには、
        <span>社内体制の整備が必要不可欠</span>
        となりますので、必ず見直しを行いましょう。
        <br />
        <br />
        実際に勤怠管理システムを導入する際に気をつけるべき点としては、
        <span>
          「会社の規模や雇用形態、就労状況にあわせたシステムを選択すること」
        </span>
        です。
        <br />
        多くの従業員を抱える規模の大きな会社の場合、フルタイム勤務者や育児・介護による短時間勤務者、パート・アルバイトなど、複数の雇用形態で働く社員が存在するケースが多くみられます。このような会社では、総務・人事・経理部門の社員管理を一括して行うことのできる、ある程度の規模をもったシステムで、会社のルールに沿ったオプションを加えていく方法を検討すると良いでしょう。
        <br />
        このような勤怠管理システムは高額である場合もありますが、管理部署の社員の負担を軽減し、不要な残業時間を削減できるという効果も含めて、どの部分にどこまでのコストをかけるかを入念に検討していくことが重要です。
        <br />
        <br />
        一方、規模の小さな会社の場合は、大企業と比較すると社内独自のルールが少ないことが予想されるため、パッケージ販売のされているコストを抑えた勤怠管理システムを検討することで、会社側の負担を軽減することができるでしょう。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        勤怠管理システムを導入するためには、社内体制の見直しが重要であることがお分かりいただけたかと思います。
        <br />
        昨今では、勤怠入力忘れを防ぐためのアラートシステムや、パソコン画面の定期的なスクリーンショット保存システムなど、在宅勤務に適したさまざまな機能を含んだ勤怠管理システムも多く展開されています。
        <br />
        会社・社員がともに負担なく記録できるようなシステムを検討してみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0003
