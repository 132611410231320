import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0003 from '../../../components/molecules/columnDetailInner0003'
import ColumnRelationCards0003 from '../../../components/molecules/columnRelationCards0003'
import UsefulFunctionLayout010 from '../../../components/molecules/usefulFunctionLayout010'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0003 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '勤怠管理システムを導入したい！メリットや気をつけることとは',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="勤怠管理システムを導入したい！メリットや気を付けるべき点をとは"
        description="勤怠管理システムは、出退勤打刻から休暇や残業の申請、労働時間の集計など、勤怠管理に関する業務全般を支援するシステム。導入することで得られるメリットや注意点とは？"
        ogUrl="https://kintaicloud.jp/column/details/C0003/"
        ogType="article"
        ogTitle="勤怠管理システムを導入したい！メリットや気を付けるべき点をとは"
        ogDescription="勤怠管理システムは、出退勤打刻から休暇や残業の申請、労働時間の集計など、勤怠管理に関する業務全般を支援するシステム。導入することで得られるメリットや注意点とは？"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0003.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0003 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout010 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0003 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0003

export const pageQuery = graphql`
  query C0003 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
